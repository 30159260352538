import { StyleSheet, Text, View, Image, FlatList, TextInput, Pressable, ScrollView, SectionList, KeyboardAvoidingView} from 'react-native';
import { useFonts } from 'expo-font';
import React, { useState, useMemo, useEffect } from 'react';
import { Picker } from '@react-native-picker/picker';

// imporitng the supabase JS client

import { createClient } from '@supabase/supabase-js'
import 'react-native-url-polyfill/auto'





export default function App() {

  const supabase = createClient('https://ifvhiozbclwcwbthoeku.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imlmdmhpb3piY2x3Y3didGhvZWt1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTQwMDAyNjksImV4cCI6MjAwOTU3NjI2OX0.c92SIoemIs7y89IqArAIagMWDEbKNxl4d6usCPt3SUs', {
    auth: {
      autoRefreshToken: true,
      persistSession: true,
      detectSessionInUrl: false,
    },
  })
  
  const [fontsLoaded, fontError] = useFonts({
    'Indie': require('./assets/fonts/IndieFlower-Regular.ttf'),
  });

  const [beers, setBeers] = useState([]);

  useEffect(() => {
    const fetchBeers = async () => {
      try {
        const { data, error } = (await supabase.from('beer_prices').select('Beer'));
        var beer_list = data.map((beer) => beer.Beer)
        if (error) throw error;
        setBeers(beer_list);
      } catch (error) {
        console.error("Error fetching beers:", error);
      }
    };
  
    fetchBeers();
  }, []);


  const [searchBeers, setSearchBeers] = useState([]);

  const [selectedBeer, setSelectedBeer] = useState('');

  const [step, setStep] = useState(1);


  const next90days = () => {
    const dates = [];
    for (let i = 0; i < 120; i++) {
      const date = new Date();
      date.setDate(date.getDate() + i);
      dates.push(date.toDateString());
    }
    return dates;
  }
  
  const [submitted, setSubmitted] = useState(false);
  const [datearray, setDatearray] = useState(next90days());

  const [date, setDate] = useState(datearray[0]);

  const [firstName, setFirstName] = useState('');

  const [email, setEmail] = useState('');

  const [phone, setPhone] = useState('');

  const [validated, setValidated] = useState(true);

  const validate = (email, phone, name) => {
    if (email.includes('@') && email.includes('.') && phone.length == 10 && !isNaN(phone) && phone.startsWith('04') && name !== '') {
      return true;
    } else {
      return false;
    }
}


  const submit = (beer, name, email, phone, date) => {
    supabase
    .from('booking_requests')
    .insert([
      { beer: beer, name: name, email: email, phone: phone, date: date}
    ])
    .then(data => {
      if (data.status == 201) {
        setStep(5);
        setSubmitted(true);
      }
      else {
        setSubmitted(false)
      }
    })
  }

  useEffect(() => {
  }, [date]);


  const searchBeer = (text) => {
    setSelectedBeer('');
    if (text == '') {
      setSearchBeers([])
    }
    else{
      const filteredBeers = beers.filter((beer) => {
        const beerName = beer.toLowerCase(); // Assuming each beer is a string in the array
        const searchText = text.toLowerCase();
        return beerName.includes(searchText);
      });
      setSearchBeers(filteredBeers);
    }
  }

  const selectBeer = (beer) => {
    setSelectedBeer(beer);
    setSearchBeers([]);
  }
  
  if (fontsLoaded) {
  return (
    <ScrollView>
    <View style={styles.container}>
      <Image
          style={styles.logo}
          source={require('./assets/logo.jpg')}>
      </Image>

      {/* when the step = 1, render this view */}
      {step == 1 && <View style={{width: '100%', alignItems: 'center', height: 'auto'}}>
        <Text style={styles.heading}>Choose a Beer</Text>
        <Text style={styles.subheading}>( We also might be able to find you craft beers .. just pick one anyway and let us know when we get in touch )</Text>
        <TextInput
          style={styles.search_bar}
          onChangeText={(text) => searchBeer(text)}
          placeholder="Search Beer"
          defaultValue=''
        />
        <Text style={styles.heading}>{selectedBeer}</Text>
        <FlatList data={searchBeers} scrollEnabled={false}
            renderItem={({item}) => (<Pressable style={{borderColor: 'white', borderBottomWidth: 2}} onPress={() => {selectBeer(item)}}>
              <Text style={{fontFamily: 'Indie', color: 'white', fontSize: 35}}>{item}</Text>
              </Pressable>)}
            style={styles.beerlist}
        >
        </FlatList>
      </View>}

      {/* when the step = 2, render this view */}
      {step == 2 && <View style={{width: '100%', alignItems: 'center', height: 'auto'}}>
        <Text style={styles.heading}>Choose a Beer</Text>
        <Text style={styles.subheading}>( We also might be able to find you craft beers .. just pick one anyway and let us know when we get in touch )</Text>
        <TextInput
          style={styles.search_bar}
          onChangeText={(text) => searchBeer(text)}
          placeholder="Search Beer"
          defaultValue=''
        />
        <Text style={styles.heading}>{selectedBeer}</Text>
          <FlatList data={searchBeers} scrollEnabled={false}
                    renderItem={({item}) => (<Pressable style={{borderColor: 'white', borderBottomWidth: 2}} onPress={() => {selectBeer(item)}}>
                      <Text style={{fontFamily: 'Indie', color: 'white', fontSize: 35}}>{item}</Text>
                      </Pressable>)}
                    style={styles.beerlist}
          >
          </FlatList>
      </View>}

      {step == 3 && <View style={{height: 'auto', alignItems: 'center', width: '100%'}}>
        <Text style={styles.heading}>Pick a Date</Text>
        {/* using Picker and an datearray for the user to be able to pick a date */}

        <Picker
          selectedValue={date}
          onValueChange={(itemValue) => {
              setDate(itemValue);
          }}
          style={{width: '80%', backgroundColor: 'white', borderRadius: 5, fontFamily: 'Indie', height: 'auto' }}
      >
          {datearray.map((date, index) => {
              return <Picker.Item label={date} value={date} key={index} />
          })}
      </Picker>
      </View>}
      
      {step == 4 && <View style={{height: 'auto', alignItems: 'center', width: '100%'}}>
        <Text style={styles.heading}>Enter Your Details</Text>
        <TextInput
        style={styles.search_bar}
        placeholder="Enter Your Name"
        defaultValue=''
        onChangeText={(text) => {setFirstName(text)}}
      />
      <TextInput
        style={styles.search_bar}
        placeholder="Enter Your Email"
        defaultValue=''
        onChangeText={(text) => {setEmail(text)}}
      />
      <TextInput
        style={styles.search_bar}
        placeholder="Enter Your Phone Number Here"
        defaultValue=''
        onChangeText={(text) => {setPhone(text);}}
      />
      {validated == false && <Text style={{color: 'red', fontFamily: 'Indie', fontSize: 20}}>Please enter a valid email, phone number and name</Text>}
      </View>}

      {step !== 5 && <Pressable 
          style={{
              borderColor: 'white', 
              borderRadius: 20, // Adjust this value to get the desired roundness
              borderWidth: 2,   // This will set the border width
              alignItems: 'center', // To center the text inside
              paddingVertical: 10,  // To give some vertical padding
              width: '30%',
              marginTop: '5%'
          }}
          onPress={() => { if (step != 4) {setStep(step + 1);} else {if (validate(email, phone, firstName)) {submit(selectedBeer, firstName, email, phone, date);} else {setValidated(false)}}}}
      >
      <Text style={{color: 'white', fontFamily: 'Indie', fontSize: 25, textAlign: 'center'}}>Next</Text>
      </Pressable>}

      {step == 5 && <View style={{height: 'auto', alignItems: 'center', width: '100%'}}>
        <Text style={styles.heading}>Thanks for your interest!</Text>
        <Text style={styles.subheading}>We will be in touch soon!</Text>
      </View>}
      
    </View>
    </ScrollView>
  );
}}


const styles = StyleSheet.create({
  container: {
    flex: 1, // Ensure ScrollView fills its container
    backgroundColor: '#073F6E',
    fontFamily: 'Indie',
    minHeight: '100%',
    alignItems: 'center',
  },
  logo: {
    width: 400,
    height: 284,
    marginBottom: 10,
    marginTop: '10%', // Adjusted margin for better spacing 
  },
  heading: {
    fontSize: 25,
    fontWeight: 'bold',
    color: '#fff',
    fontFamily: 'Indie',
    marginTop: '5%'
  },
  subheading: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#fff',
    fontFamily: 'Indie',
    marginTop: '2%',
    marginBottom: '2%',
    marginLeft: '5%',
    marginRight: '5%',
  },
  img_container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '5%',
    gap: 20,
    height: 'auto'
  },
  search_bar: {
    height: 40,
    width: '80%',
    borderColor: 'gray',
    borderWidth: 1,
    marginTop: '5%',
    backgroundColor: '#fff',
    borderRadius: 5,
    paddingLeft: 10,
    paddingRight: 10,
    fontFamily: 'Indie'
  },
beerlist: {
  flex: 1,
  flexDirection: 'column',
  marginTop: '5%',
  gap: 1,
  height: 'auto',
  width: '80%',
  fontFamily: 'Indie'
}}
);
